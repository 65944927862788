import { Link } from 'react-router-dom';

const ThankYouPage = () => {
  return (
    <div className='bg-bg h-full pt-8 md:pt-10 '>
      <div className=' grid  place-content-center max-w-max mx-auto xs:px-4  xs:py-auto md:px-10'>
        <div className='card bg-cardBg rounded-lg justify-items-stretch max-w-4xl'>
          <div className='grid grid-cols-1 place-content-center text-center lg:p-4'>
            <div className='justify-item-center justify-content-center mx-auto lg:pt-2 w-7/12'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/5f797864-0ca5-44b4-591d-f9e2494a0a00/public'
                alt='thankYouDuck'
                className='mx-auto'
              ></img>
            </div>
            <div className='font-bold text-xl lg:text-3xl mt-8 mx-8 my-8'>
              Thank you for sharing!
            </div>
            <div className=' text-center mb-4'>
              <button className='bg-[#1D6CB6]  font-bold rounded-xl lg:px-8 lg:py-2 lg:text-base xs:px-2 py-2 text-xs'>
                <Link to={'/feed'}>Feed the Ducks</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;

import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import {
  CLAIM_RAID_REWARD,
  GET_RAID_TIME,
} from '../graphql/mutations/operations';
import formatDate from '../utilities/formatDate';
import { useUser } from '../Context/UserContext';
import loginURL from '../utilities/loginURL';

const RaidListItem = ({ raid, index, claimedStatus }) => {
  const [raidStatus, setRaidStatus] = useState({ minutes: 0, seconds: 0 });
  const [timer, setTimer] = useState(false);
  const [claimed, setClaimed] = useState(claimedStatus);

  const user = useUser();

  const [getRaidTimer] = useMutation(GET_RAID_TIME);
  const [claimRaidReward] = useMutation(CLAIM_RAID_REWARD);

  const updateRaidStatus = async () => {
    const raidTimestamp = new Date(raid.createdAt);
    const currentTime = new Date();
    const timeDifference = currentTime - raidTimestamp;

    const maxDifference = 5 * 60 * 1000; // Max time difference is set to 5 minutes

    if (timeDifference <= maxDifference) {
      try {
        const { data } = await getRaidTimer({
          variables: { twitchRaidId: raid.id },
        });
        if (data) {
          setRaidStatus({
            minutes: data.raidClosesIn.results[0].minutesUntilClose,
            seconds: data.raidClosesIn.results[0].secondsUntilClose,
          });
          // if (!claimed) {
          setTimer(true);
          // }
        }
      } catch (error) {
        console.error('Error in getting raid claim time : ', error);
      }
    } else {
      setRaidStatus({ minutes: 0, seconds: 0 });
    }
  };

  const claimRewardClickHandler = async () => {
    try {
      const { data } = await claimRaidReward({
        variables: { twitchRaidId: raid.id },
      });
      if (data) {
        toast.success('Raid reward claimed!');
        setClaimed(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateRaidStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRaidTimer, raid.id, raid.createdAt]);

  useEffect(() => {
    if (timer) {
      const timerId = setInterval(() => {
        if (raidStatus.seconds === 0) {
          if (raidStatus.minutes === 0) {
            clearInterval(timerId);
            setRaidStatus({ minutes: 0, seconds: 0 });
            setTimer(false);
          } else {
            setRaidStatus((prev) => ({
              minutes: prev.minutes - 1,
              seconds: 59,
            }));
          }
        } else {
          setRaidStatus((prev) => ({ ...prev, seconds: prev.seconds - 1 }));
        }
      }, 1000);

      if (raidStatus.minutes === 0 && raidStatus.seconds === 0) {
        setTimer(false);
      }

      return () => {
        clearInterval(timerId);
      };
    }
  }, [timer, raidStatus.minutes, raidStatus.seconds]);

  return (
    <div
      className={`grid grid-cols-4 justify-items-centre text-white font-bold text-sm md:text-md lg:text-lg xs:px-2 sm:px-4 md:px-8 lg:px-10 ${
        index % 2 === 0
          ? 'bg-[#363636] bg-opacity-50  '
          : 'bg-[#464646] bg-opacity-50'
      }`}
    >
      <div className='ml-2 my-2'>
        {formatDate(raid.createdAt, { time: true })}
      </div>
      <div className='ml-2 sm:ml-4 my-2 overflow-ellipsis overflow-hidden'>
        {raid.streamer}
      </div>
      <div className='my-2 mr-2 md:mr-4 text-right'>
        {raidStatus.minutes === 0 && raidStatus.seconds === 0 ? (
          <span>{claimed ? '' : 'Closed'}</span>
        ) : !claimed ? (
          <p>
            Time Remaining{' '}
            <span>
              0{raidStatus.minutes}:{raidStatus.seconds <= 9 && '0'}
              {raidStatus.seconds}
            </span>
          </p>
        ) : (
          <button className='bg-[#1D6CB6]  font-bold rounded-xl lg:px-8 lg:py-2 lg:text-base xs:px-2 py-2 text-xs'>
            <Link to={'/feed'}>Feed the Ducks</Link>
          </button>
        )}
      </div>
      {
        <div className='my-2 mr-2 text-center'>
          {timer &&
            !claimed &&
            (user.user ? (
              <button
                className='bg-green  rounded-xl my-2 px-2 py-1 lg:text-lg md:px-4 md:py-1'
                onClick={claimRewardClickHandler}
              >
                +1 token
              </button>
            ) : (
              <Link
                to={loginURL}
                className='bg-blue  rounded-xl my-2 px-2 py-1 lg:text-lg md:px-4 md:py-1'
              >
                Log In
              </Link>
            ))}
          {claimed && <p className='text-green'>Claimed</p>}
        </div>
      }
    </div>
  );
};

export default RaidListItem;

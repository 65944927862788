import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUser } from '../Context/UserContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SHARE_MYSTERY } from '../graphql/mutations/operations';
import { MYSTERY_DETAILS } from '../graphql/queries/operations';

const ShareMysteryPage = () => {
  const [mysteryShared, setMysteryShared] = useState(false);

  const { mysteryId } = useParams();

  const user = useUser();
  const navigate = useNavigate();

  const [getMysteryDetails] = useLazyQuery(MYSTERY_DETAILS);
  const [shareMysteriesFunc] = useMutation(SHARE_MYSTERY);

  const copyHandler = async () => {
    try {
      if (user.videoMysteries?.link?.length) {
        const link = await navigator.clipboard.writeText(
          `${user.videoMysteries.link}`
        );
        toast.info('Link copied', link);
        setMysteryShared(true);
      } else {
        try {
          const { data } = await getMysteryDetails({
            variables: { mysteryId: mysteryId },
          });
          await navigator.clipboard.writeText(`${data.videoMystery.link}`);
          toast.info('Link copied');
          setMysteryShared(true);
        } catch (error) {}
      }
    } catch (error) {
      console.error('Error copying:', error);
    }
  };

  const sharedClickHandler = async () => {
    try {
      const { data } = await shareMysteriesFunc({
        variables: { videoId: mysteryId },
      });
      if (data) {
        user.setSharedMysteries((prev) => [...prev, { videoId: mysteryId }]);
        toast.success('Congratulations! You earned 5 feed tokens');
        navigate('/thank-you');
      }
    } catch (error) {
      console.error('Error while sharing mystery : ', error);
    }
  };

  return (
    <div className='bg-bg h-full pt-8 md:pt-10 '>
      <div className=' grid  place-content-center max-w-max mx-auto xs:px-4  xs:py-auto md:px-10'>
        <div className='card bg-cardBg rounded-lg justify-items-stretch max-w-4xl'>
          <div className='grid grid-cols-1 place-content-center text-center lg:p-4'>
            <div className='font-bold  lg:text-3xl xs:text-xl mt-6 xs:mx-8 md:mx-8'>
              Invite a Twitch streamer to solve this mystery and earn +5 tokens!
            </div>
            <div>
              <button
                className='bg-blue font-bold rounded-xl px-12 py-2 text-lg  lg:px-16 my-4 md:mt-8'
                onClick={copyHandler}
              >
                Copy Link to Video
              </button>
            </div>
            <div
              className={`justify-item-center justify-content-center mx-auto lg:pt-2 w-7/12 `}
            >
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/c084d61f-b8fe-4159-c030-9b02b1a40800/public'
                alt='richDuck'
                className='mx-auto'
              ></img>
            </div>
            {/* {mysteryShared && ( */}
            <div className={`my-8 transition-all duration-500 ease-out`}>
              <button
                className={`${
                  mysteryShared
                    ? 'bg-green cursor-pointer'
                    : 'bg-text cursor-not-allowed'
                }  font-bold rounded-xl px-10 py-3 lg:px-16 lg:py-3 lg:text-2xl place-content-end`}
                disabled={!mysteryShared}
                onClick={() => sharedClickHandler()}
              >
                I've Shared
              </button>
            </div>
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareMysteryPage;

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  FEED_REDEEM,
  BALANCE_DATA,
  FEED_HISTORY,
  GET_FEED_LIMIT_VALUE,
} from '../graphql/mutations/operations';
import FeedAnimationImage from '../Images/DukFeeder-animation-small.webp';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { JWTValidationContext } from '../Components/JWTValidationProvider';
import { toast } from 'react-toastify';
import dragElement from '../utilities/dragElement';
import dragTouchElement from '../utilities/dragTouchElement';
import { CHECK_FEED_DEBIT } from '../graphql/queries/operations';

const FeedingContext = createContext();

export const FeedingContextProvider = ({ children }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [balance, setBalance] = useState(0);
  const [coinDropped, setcoinDropped] = useState(false);
  const [coinVisibility, setcoinVisibility] = useState(true);
  const [dropArea, setdropArea] = useState(false);
  const [showHelp, setShowHelp] = useState(true);

  const [feedHistory, setFeedHistory] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [endOfData, setEndOfData] = useState(false);

  const [feedLimitValue, setFeedLimitValue] = useState(5);

  // references for the elements
  const containerRef = useRef(null);
  const coinRef = useRef(null);
  const candyMachineRef = useRef(null);

  const user = useUser();
  const { checkTokenValidity } = useContext(JWTValidationContext);

  const navigate = useNavigate();

  const CoinMachine =
    'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/5674528d-7b00-4ee7-9d83-aefe161a7100/profile';

  //Coin drag and drop
  let originalTop = null;
  let originalLeft = null;

  const startAnimation = () => {
    const img = document.getElementById('candy-machine');
    img.src = FeedAnimationImage;
  };

  const stopAnimation = () => {
    const img = document.getElementById('candy-machine');
    img.src = CoinMachine;
  };

  //graphql feed credits and feed debits query
  const [checkFeedDebitFunc] = useLazyQuery(CHECK_FEED_DEBIT);

  //graphql balance mutation
  const [balanceData, { balanceError }] = useMutation(BALANCE_DATA);
  if (balanceError) {
    console.log(balanceError);
  }

  //graphql feed redeem mutation
  const [feedRedeem, { feedRedeemError }] = useMutation(FEED_REDEEM);
  const [getFeedHistoryFunc] = useMutation(FEED_HISTORY);

  if (feedRedeemError) {
    console.error('feed redeem error :', feedRedeemError);
  }

  const addtoCoinMachine = async () => {
    if (balance === 0) {
      toast.error('You have got no feed balance');
    } else {
      setBalance((prev) => prev - 1);
      setShowHelp(false);
      if (feedLimitValue > 0) {
        try {
          await feedRedeem();

          const { data } = await getFeedHistoryFunc({
            variables: { pageOffset: 0 },
          });

          if (data) {
            const newTransaction = data.feedHistory?.results[0];

            if (newTransaction) {
              setFeedHistory((prev) => [newTransaction, ...prev]);
            }

            setFeedLimitValue((prev) => prev - 1);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  };

  const getFeedHistory = async (pageOffset = 0) => {
    try {
      const { data } = await getFeedHistoryFunc({
        variables: { pageOffset: pageOffset },
      });
      if (data) {
        const newFeedData = data.feedHistory?.results || [];

        setFeedHistory((prev) => {
          const existingTransactions = new Set(
            prev.map((item) => item.createdAt)
          ); //creates unique array based on assumption that createdAt will be unique for each transaction

          const updatedFeedHistory = newFeedData.reduce(
            (acc, item) => {
              if (!existingTransactions.has(item.createdAt)) {
                existingTransactions.add(item.createdAt);
                acc.push(item);
              }
              return acc;
            },
            [...prev]
          );

          if (!newFeedData.length) {
            setEndOfData(true);
          }

          return updatedFeedHistory;
        });
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const checkFeedDebit = async () => {
    try {
      const { data } = await checkFeedDebitFunc();
      if (data && data.feedDebits.edges.length) {
        setShowHelp(false);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    if (showHelp) {
      checkFeedDebit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHelp]);

  useEffect(() => {
    getFeedHistory(pageOffset);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset]);

  useEffect(() => {
    if (user.token && user.user) {
      balanceData({
        variables: {
          clientMutationId: 'none',
        },
      }).then((data) => {
        setBalance(data.data.balance.integer);
      });
      getFeedHistory();
    } else if (user.token) {
      checkTokenValidity(user.token);
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const dragable = coinRef.current;
    const dragzone = containerRef.current;

    if (isClicked) {
      if (dragable && dragzone)
        dragElement(
          dragable,
          dragzone,
          originalTop,
          originalLeft,
          dropArea,
          setdropArea,
          candyMachineRef
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, dropArea, coinDropped]);

  useEffect(() => {
    if (coinDropped) {
      // Reset the coin's position to the original
      const dragable = coinRef.current;
      if (dragable) {
        dragable.style.top = originalTop;
        dragable.style.left = originalLeft;
      }
    }

    if (isClicked) {
      const dragable = coinRef.current;
      const dragzone = containerRef.current;
      if (dragable && dragzone)
        dragTouchElement(
          dragable,
          dragzone,
          originalTop,
          originalLeft,
          dropArea,
          setdropArea,
          candyMachineRef,
          setcoinDropped
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, dropArea, coinDropped]);

  //updating Coin drop to candy machine
  useEffect(() => {
    if (dropArea && !coinDropped) {
      addtoCoinMachine();
      setcoinDropped(true);
      setdropArea(false);
      setcoinVisibility(false);
      startAnimation();
    }
    if (coinDropped) {
      setTimeout(() => {
        setcoinDropped(false);
        setcoinVisibility(true);
        stopAnimation();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinDropped, dropArea]);

  // graphql feedLimit value mutation
  const [getFeedLimitValueFunc] = useMutation(GET_FEED_LIMIT_VALUE);

  const getFeedLimitValue = useCallback(async () => {
    try {
      const { data } = await getFeedLimitValueFunc();
      if (data) {
        setFeedLimitValue(data.feedLimit.integer);
      }
    } catch (error) {
      console.error('Error in getting feed limit value : ', error);
    }
  }, [getFeedLimitValueFunc, setFeedLimitValue]);

  useEffect(() => {
    const fetchAndUpdateValue = () => {
      // console.log('Fetching Limit Integer');
      getFeedLimitValue();
    };

    fetchAndUpdateValue();

    if (feedLimitValue < 5) {
      const intervalId = setInterval(fetchAndUpdateValue, 2 * 60 * 1000); // 2 minutes in milliseconds

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedLimitValue, getFeedLimitValue]);

  return (
    <FeedingContext.Provider
      value={{
        isClicked,
        setIsClicked,
        balance,
        setBalance,
        coinDropped,
        setcoinDropped,
        coinVisibility,
        setcoinVisibility,
        showHelp,
        setShowHelp,
        feedHistory,
        pageOffset,
        setPageOffset,
        endOfData,
        feedLimitValue,
        setFeedLimitValue,
        containerRef,
        coinRef,
        candyMachineRef,
      }}
    >
      {children}
    </FeedingContext.Provider>
  );
};

export const useFeed = () => {
  return useContext(FeedingContext);
};

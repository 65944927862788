import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { useUser } from '../Context/UserContext';
import { SUBMIT_SOLUTION } from '../graphql/mutations/operations';
import { MYSTERY_DETAILS_WITH_SOLUTION } from '../graphql/queries/operations';

const SolveMysteryPage = () => {
  const [selectedItem, setselectedItem] = useState();
  const [mysterySolution, setmysterySolution] = useState({});

  const user = useUser();

  const navigate = useNavigate();

  //checking for jwt expiration
  useEffect(() => {
    if (user.token && user.user) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [submitSolutionData] = useMutation(SUBMIT_SOLUTION, {
    context: {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    },
  });

  const { nodeId } = useParams();

  // getting VideoMysteries  with ID from graphql

  const {
    data: videodata,
    error: videoerror,
    loading: videoloading,
  } = useQuery(MYSTERY_DETAILS_WITH_SOLUTION, {
    variables: { VideoId: nodeId },
  });

  if (videoloading) {
    return <p>loading...</p>;
  } else if (videoerror) {
    console.log(videoerror);
  }

  //submitting solution
  const submitHandler = () => {
    submitSolutionData({
      variables: {
        solutionId: mysterySolution.id,
      },
    }).then((data) => {
      if (data) {
        user.setvideoMysteriesSolution(data);

        // temporary fix to force rerender and maintain state
        user.setMysteryGuesses((prev) => [
          ...prev,
          {
            ...videodata.videoMystery,
            videoMysteryId: videodata.videoMystery.id,
          },
        ]);
        navigate('/mysteries/result');
      }
    });
  };

  //handling selected solution
  const handleSolution = (item, index) => {
    setselectedItem(index);
    setmysterySolution(item);
    user.setvideoMysteriesAnswer(item);
    localStorage.setItem('link', item.link);
  };

  return (
    <>
      <div className='bg-bg h-full py-8 md:py-10 '>
        <div className=' grid place-content-center m-auto w-[90%] md:w-[80%] max-w-4xl'>
          <div className='card bg-cardBg rounded-lg p-4'>
            <div className='flex'>
              <div className='grow backIcon '>
                <Link to='/mysteries'>
                  <i
                    style={{ fontSize: '27px' }}
                    className='fa fa-chevron-left '
                    aria-hidden='true'
                  ></i>
                </Link>
              </div>
              <div className='font-bold text-xl '>
                #{videodata.videoMystery.vidNumber}
              </div>
            </div>
            <div className='max-w-3xl m-auto'>
              <div className='text-center font-bold  text-xl mt-7 lg:text-4xl  md:text-3xl md:py-4 '>
                {videodata.videoMystery.title}
              </div>
            </div>
            <div className=' text-center mt-4'>
              <button className='bg-[#1D6CB6]  font-bold rounded-xl lg:px-8 lg:py-2 lg:text-base xs:px-2 py-2 text-xs'>
                <Link to={videodata.videoMystery.link} target='_blank'>
                  Watch Video
                </Link>
              </button>
            </div>

            {videodata.videoMystery.videoMysterySolutions.nodes.map(
              (obj, index) => (
                <div
                  onClick={() => {
                    handleSolution(obj, index);
                  }}
                  key={obj.id}
                  className={`flex flex-col items-center md:flex-row my-6 p-2 md:p-5 hover:bg-[#A9A9A9] hover:bg-opacity-30 cursor-pointer ${
                    index === selectedItem
                      ? ' bg-[#A9A9A9] bg-opacity-30'
                      : ' bg-none'
                  }`}
                >
                  <img
                    className='solutionImage rounded-full xs:h-[100px] xs:w-[100px]   md:h-[110px] md:w-[110px] lg:h-[140px] lg:w-[140px] border-[6px] border-borderColor '
                    src={obj.solutionImg}
                    alt='videoMysterySolutionsImage'
                  ></img>

                  <div className='text-center font-bold p-2 md:ml-4 sm:text-lg md:text-2xl lg:text-left md:text-left'>
                    {obj.solutionText}
                  </div>
                </div>
              )
            )}

            <div className='flex flex-row justify-center md:flex-row-reverse md:justify-start items-end'>
              <button
                className='bg-green  font-bold rounded-xl xs:px:4 xs:py-3 px-10 py-3  md:px-14 md:py-3 md:text-2xl'
                onClick={() => {
                  submitHandler();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolveMysteryPage;

import React from 'react';
import { useFeed } from '../Context/FeedingContext';
import { MdSchedule } from 'react-icons/md';

const FeedLimitProgressBar = () => {
  const feed = useFeed();

  const bars = [];

  for (let i = 0; i < 5; i++) {
    bars.push(
      <div
        key={i}
        className={`h-1 w-8 md:h-2 md:w-12 m-[1px] ${
          i < feed.feedLimitValue
            ? 'feedLimitIndicator border-1 md:border-2 border-[#804F06]'
            : 'bg-cs-gray-500 border-1 md:border-2  border-cs-gray-700'
        } `}
      ></div>
    );
  }
  return (
    <div className='flex  justify-center  rounded-b-lg gap-2 mb-2'>
      <div className='flex flex-col-reverse items-center '>{bars}</div>

      <div className='flex flex-col justify-end items-center'>
        <MdSchedule width='30px' height='30px' fill='#817C74' />
        <p className='text-cs-gray-500 font-bold text-xs md:text-sm lg:text-md'>
          1 hr
        </p>
      </div>
    </div>
  );
};

export default FeedLimitProgressBar;

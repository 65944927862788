import { gql } from '@apollo/client';

// Mystery related mutations

export const SUBMIT_SOLUTION = gql`
  mutation SubmitMutation($solutionId: UUID!) {
    submitSolution(input: { solutionId: $solutionId }) {
      basicReply {
        message
        success
      }
    }
  }
`;

export const SHARE_MYSTERY = gql`
  mutation shareVideo($videoId: UUID!) {
    submitVideoMysteryShare(input: { videoId: $videoId }) {
      clientMutationId
    }
  }
`;

export const GET_SHARE_MYSTERY_TIMER = gql`
  mutation getMysteryShareTimer {
    canEarnIn(input: {}) {
      results {
        hoursUntilShare
        minutesUntilShare
      }
    }
  }
`;

// Feed related mutations

export const BALANCE_DATA = gql`
  mutation getBalance {
    balance(input: { clientMutationId: "123" }) {
      clientMutationId
      integer
    }
  }
`;

export const FEED_REDEEM = gql`
  mutation redeemFeed {
    redeemFeed(input: {}) {
      basicReply {
        message
        success
      }
    }
  }
`;

export const FEED_HISTORY = gql`
  mutation feedHistoryCombined($pageOffset: Int!) {
    feedHistory(input: { pageLimit: 10, pageOffset: $pageOffset }) {
      results {
        createdAt
        reason
        amount
      }
    }
  }
`;

export const GET_FEED_LIMIT_VALUE = gql`
  mutation getFeedLimit {
    feedLimit(input: {}) {
      integer
    }
  }
`;

// Daily photo related mutations

export const SHARE_PHOTO = gql`
  mutation sharePhoto($photoId: UUID!) {
    submitPhotoShare(input: { photoId: $photoId }) {
      clientMutationId
    }
  }
`;

export const RESHARE_PHOTO_TIMER = gql`
  mutation getShareTimer {
    photoCanShareIn(input: {}) {
      clientMutationId
      results {
        hoursUntilShare
        minutesUntilShare
      }
    }
  }
`;

// Raid related mutations

export const GET_RAID_TIME = gql`
  mutation raidClosesIn($twitchRaidId: UUID!) {
    raidClosesIn(input: { twitchRaidId: $twitchRaidId }) {
      clientMutationId
      results {
        minutesUntilClose
        secondsUntilClose
      }
    }
  }
`;

export const CLAIM_RAID_REWARD = gql`
  mutation claimRaidToken($twitchRaidId: UUID!) {
    raidClaimToken(input: { twitchRaidId: $twitchRaidId }) {
      clientMutationId
      basicReply {
        message
        success
      }
    }
  }
`;

export const HATCH_A_PROGRESS_REWARD = gql`
  mutation hatchAProgressReward($rewardID: UUID!) {
    hatchRewardRedeem(input: { rewardId: $rewardID }) {
      clientMutationId
    }
  }
`;

export const TOKENS_EARNED = gql`
  mutation tokensEarned {
    tokensEarned(input: {}) {
      integer
    }
  }
`;

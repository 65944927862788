import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message }) => {
      return alert(`GraphQl Error ${message}`);
    });
  }
  if (networkError) {
    console.log('network error ->', networkError);
  }
});

const httpLink = from([
  errorLink,
  new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  }),
]);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  // add the authorization to the headers
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
  }

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, httpLink]),
});

import { Link } from 'react-router-dom';

const CardButton = ({ bg, title, link, openInNewTab = true }) => {
  return (
    <button
      className={`${bg}  font-bold rounded-xl lg:px-8 lg:py-2 lg:text-base px-4 py-2 text-sm`}
    >
      <Link to={link} target={openInNewTab && '_blank'}>
        {title}
      </Link>
    </button>
  );
};

export default CardButton;

import { useState, useEffect, useRef } from 'react';
// import { useLazyQuery, useMutation } from '@apollo/client';

// import {
//   GET_ALL_AVAILABLE_PROGRESS_REWARDS,
//   PROGRESS_REWARDS_HATCHEDS_BY_USER,
// } from '../graphql/queries/operations';
// import { BALANCE_DATA, TOKENS_EARNED } from '../graphql/mutations/operations';
import '../styles/Progress.css';
// import { useUser } from '../Context/UserContext';
import ProgressTile from '../Components/ProgressTile';
import { useProgressDuckling } from '../Context/ProgressDucklingContext';

const ProgressPage = () => {
  // const [allAvailableRewards, setAllAvailableRewards] = useState([]);
  // const [tokensEarned, setTokensEarned] = useState(0);
  // const [progressRewardsHatched, setProgressRewardsHatched] = useState([]);
  const [gridInfo, setGridInfo] = useState({ rows: 0, columns: 0 });
  // const [balance, setBalance] = useState(0);
  const { allAvailableRewards, tokensEarned, progressRewardsHatched, balance } =
    useProgressDuckling();

  const containerRef = useRef(null);

  // const user = useUser();

  // const [getAllAvailableProgressRewardsFunc] = useLazyQuery(
  //   GET_ALL_AVAILABLE_PROGRESS_REWARDS
  // );
  // const [getTokensEarnedFunc] = useMutation(TOKENS_EARNED);
  // const [getProgressRewardsHatchedByUserFunc] = useLazyQuery(
  //   PROGRESS_REWARDS_HATCHEDS_BY_USER
  // );
  // const [getBalanceDataFunc] = useMutation(BALANCE_DATA);

  // const getAllAvailableProgressRewards = async () => {
  //   try {
  //     const { data } = await getAllAvailableProgressRewardsFunc();
  //     if (data) {
  //       setAllAvailableRewards(data.hatchRewards.nodes);
  //     }
  //   } catch (error) {
  //     if (error.name === 'AbortError') {
  //       return;
  //     } else {
  //       console.error('Error:', error);
  //     }
  //   }
  // };

  // const getTokensEarned = async () => {
  //   try {
  //     const { data } = await getTokensEarnedFunc();
  //     if (data) {
  //       setTokensEarned(data.tokensEarned.integer);
  //     }
  //   } catch (error) {
  //     if (error.name === 'AbortError') {
  //       return;
  //     } else {
  //       console.error('Error:', error);
  //     }
  //   }
  // };

  // const getProgressRewardsHatchedByUser = async () => {
  //   try {
  //     const { data } = await getProgressRewardsHatchedByUserFunc();
  //     if (data.hatchRewardRedeemeds.nodes.length) {
  //       console.log(data.hatchRewardRedeemeds.nodes);
  //       setProgressRewardsHatched(() => [...data.hatchRewardRedeemeds.nodes]);
  //     }
  //   } catch (error) {
  //     if (error.name === 'AbortError') {
  //       return;
  //     } else {
  //       console.error('Error:', error);
  //     }
  //   }
  // };

  // const getBalanceData = async () => {
  //   try {
  //     const { data } = await getBalanceDataFunc();
  //     if (data) {
  //       setBalance(data.balance.integer);
  //     }
  //   } catch (error) {
  //     if (error.name === 'AbortError') {
  //       return;
  //     } else {
  //       console.error('Error:', error);
  //     }
  //   }
  // };

  // const createCompleteTilesList = () => {
  //   // let completeTilesList = [];
  //   const emptyNest = {
  //     id: 'empty',
  //     img: 'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/331997d1-9c79-4588-9651-3d3576a56700/thumbnail',
  //   };
  //   const totalSlots = gridInfo.rows * gridInfo.columns;

  //   if (gridInfo.columns > 0 && gridInfo.columns < 7) {
  //     return [
  //       ...allAvailableRewards,
  //       ...Array(totalSlots - allAvailableRewards.length).fill(emptyNest),
  //     ];
  //   } else if (gridInfo.columns >= 7) {
  //     const padding = (gridInfo.columns - 5) / 2;
  //     // console.log(gridInfo);
  //     const splittedArray = splitArrayInFiveElements(
  //       [...allAvailableRewards],
  //       emptyNest,
  //       padding
  //     );
  //     return splittedArray.flat();
  //   }
  // };
  const createCompleteTilesList = () => {
    const emptyNest = {
      id: 'empty',
      img: 'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/331997d1-9c79-4588-9651-3d3576a56700/thumbnail',
    };
    const totalSlots = gridInfo.rows * gridInfo.columns;

    if (gridInfo.columns > 0 && gridInfo.columns < 6) {
      return [
        ...allAvailableRewards,
        ...Array(totalSlots - allAvailableRewards.length).fill(emptyNest),
      ];
    } else if (gridInfo.columns === 6) {
      const rowsPaddedAtEnd = [];
      const remaining = allAvailableRewards.length % 5;
      const fullRowsCount = gridInfo.rows;

      for (let i = 0; i < fullRowsCount; i++) {
        rowsPaddedAtEnd.push(
          allAvailableRewards.slice(i * 5, (i + 1) * 5),
          emptyNest
        );
      }

      if (remaining > 0) {
        rowsPaddedAtEnd.push(
          allAvailableRewards.slice(
            fullRowsCount * 5,
            fullRowsCount * 5 + remaining
          )
        );
        rowsPaddedAtEnd[rowsPaddedAtEnd.length - 1].push(emptyNest);
      }

      const emptyRowsCount = gridInfo.rows - rowsPaddedAtEnd.length;
      for (let i = 0; i < emptyRowsCount; i++) {
        const emptyRow = Array(6).fill(emptyNest);
        emptyRow[5] = null; // Remove the last empty nest from the last row
        rowsPaddedAtEnd.push(emptyRow);
      }

      return rowsPaddedAtEnd.flat();
    } else if (gridInfo.columns >= 7) {
      const padding = (gridInfo.columns - 5) / 2;
      const splittedArray = splitArrayInFiveElements(
        [...allAvailableRewards],
        emptyNest,
        padding
      );
      return splittedArray.flat();
    }
  };

  const splitArrayInFiveElements = (inputArray, emptyNest, padding) => {
    const subArrays = [];
    let currentIndex = 0;

    while (currentIndex < inputArray.length) {
      const subArray = inputArray.slice(currentIndex, currentIndex + 5);
      const paddedArray = addPaddedImagesToArray(subArray, emptyNest, padding);
      subArrays.push(paddedArray);
      currentIndex += 5;
    }

    return subArrays;
  };

  const addPaddedImagesToArray = (array, defaultValue, times) => {
    let newArray = [];
    let paddedArray = [];
    array.length < 5
      ? (paddedArray = [
          ...array,
          ...Array(5 - array.length).fill(defaultValue),
        ])
      : (paddedArray = [...array]);
    newArray = [
      ...Array(times).fill(defaultValue),
      ...paddedArray,
      ...Array(times).fill(defaultValue),
    ];

    return newArray;
  };

  // useEffect(() => {
  //   console.log('This useeffect rendered');
  //   console.log('prh -> ', progressRewardsHatched);
  //   const fetchData = async () => {
  //     try {
  //       if (user.user) {
  //         await getAllAvailableProgressRewards();
  //         await getBalanceData();
  //         await getTokensEarned();
  //         await getProgressRewardsHatchedByUser();
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };

  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const calculateGrid = () => {
      const containerWidth = containerRef.current.clientWidth;

      const emptyNestWidth = 275;

      let columns = Math.floor(containerWidth / emptyNestWidth);
      if (containerWidth > 1920) {
        columns = Math.max(7, columns | 1);
      }
      const rows = Math.ceil(allAvailableRewards.length / columns);

      // console.log('Setting grid to  ', rows, columns);
      setGridInfo({ rows, columns });
    };
    calculateGrid();

    window.addEventListener('resize', calculateGrid);

    return () => {
      window.removeEventListener('resize', calculateGrid);
    };
  }, [gridInfo.rows, allAvailableRewards]);

  return (
    <div>
      <div className='woodBg'></div>
      <div className=' py-10'>
        <div className='flex flex-col items-center justify-center xs:py-6 lg:py-8 lg:mb-3'>
          <h1 className='text-center  font-bold  xs:text-xl  sm:text-xl md:text-2xl lg:text-3xl '>
            You have earned a total of {tokensEarned ?? '0'} Tokens
          </h1>
          <h3 className='text-center font-semibold italic  xs:text-md sm:text-lg md:text-xl lg:text-2xl'>
            You currently have {balance ?? '0'} Tokens
          </h3>
        </div>
        <div
          ref={containerRef}
          id='progressRewards-container'
          className='grid w-full'
          style={{ gridTemplateColumns: `repeat(${gridInfo.columns}, 1fr)` }}
        >
          {gridInfo.rows ? (
            createCompleteTilesList().map((reward, index) => (
              <ProgressTile
                key={index}
                progressReward={reward}
                isActive={tokensEarned >= reward.tokens}
                isHatched={progressRewardsHatched.some(
                  (item) => reward.id === item.hatchRewardId
                )}
                isEmpty={reward.id === 'empty'}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressPage;

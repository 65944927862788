import React from 'react';
import { Link } from 'react-router-dom';
import { useFeed } from '../Context/FeedingContext';
import { FatDownArrow, FatRightArrow } from '../utilities/SVGIcons';
import FeedLimitProgressBar from './FeedLimitProgressBar';

// Images Links
const bagEmptyClosed =
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/883a6183-14cd-47f1-e274-fbe6b4cb1200/profile';
const bagEmptyOpen =
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/13d24520-e1e5-4ec5-b632-ac104bdc9b00/profile';
const bagFilledClosed =
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/aad23e31-1f85-4a28-8fed-9c26a3610100/profile';
const bagFilledOpen =
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/238a00a8-9a5b-46c1-6379-0fa7fc286100/profile';
const CoinMachine =
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/5674528d-7b00-4ee7-9d83-aefe161a7100/profile';

const coinImagesArray = [
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/60fb0c2f-3713-4b36-30cd-ec9c2bf08500/profile',
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/8b687f7e-0c2a-4cbc-fc69-4802efc58b00/profile',
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/f2c4cabe-d8cf-46db-a0aa-864f5edf1300/profile',
  'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/ee72cf51-f831-4f71-b489-5ee7bad3df00/profile',
];

const FeedingCard = () => {
  const feed = useFeed();

  //random Coin Image Generator
  let randomImage =
    coinImagesArray[Math.floor(Math.random() * coinImagesArray.length)];

  const balanceDisplayStyle = {
    WebkitTextStroke: '2px black',
    WebkitTextFillColor: 'white',
  };

  return (
    <>
      <div
        id='dragzone'
        ref={feed.containerRef}
        className='feedCard relative  min-w-full rounded-t-lg mx-auto min-h-[200px] h-[32vh] max-h-[320px] grid md:grid-cols-[65%_35%] md:grid-rows-[100%] xs:grid-cols-[100%] xs:grid-rows-[75%_25%]'
      >
        <div className='grid md:grid-cols-[70%_30%] xs:grid-cols-[50%_40%] gap-4 sm:gap-8 md:gap-0 relative'>
          <div className='relative'>
            {!feed.isClicked && feed.showHelp && (
              <div className='absolute bottom-[55%] left-[45%] md:bottom-[50%] md:left-[20%] flex flex-col justify-center items-center whitespace-nowrap'>
                <p className='hidden md:block'>Click To Open Bag</p>
                <div className='mt-2 text-center animate-bounce'>
                  <FatDownArrow size={30} fill={'#fff'} />
                </div>
              </div>
            )}
            <div
              className='absolute bottom-0 left-[20%] md:bottom-[5%] md:left-[20%] xs:w-24 sm:w-28 md:w-30 lg:w-32 flex justify-center z-10 cursor-pointer'
              onClick={() => feed.setIsClicked(!feed.isClicked)}
            >
              <div onClick={() => feed.setIsClicked(!feed.isClicked)}>
                {feed.balance === 0 ? (
                  feed.isClicked ? (
                    <img src={bagEmptyOpen} alt='coinbagemptyopen' />
                  ) : (
                    <img src={bagEmptyClosed} alt='coinbagemptyclosed' />
                  )
                ) : feed.isClicked ? (
                  <img src={bagFilledOpen} alt='coinbagfullopen' />
                ) : (
                  <img src={bagFilledClosed} alt='coinbagfullclosed' />
                )}
              </div>
              <div
                className='balanceContainer absolute font-extrabold text-2xl md:text-3xl bottom-1 sm:bottom-2 opacity-50 cursor-pointer'
                style={balanceDisplayStyle}
              >
                {feed.balance}
              </div>
              <div className='absolute bottom-[-15%]  right-[-80%] md:bottom-[-10%]'>
                <FeedLimitProgressBar />
              </div>
            </div>
            {feed.feedLimitValue ? (
              <div className='absolute bottom-[50%] right-[0] sm:right-[10%]  md:right-[30%] '>
                <div
                  id='coinKey'
                  className={`cursor-move  xs:h-16 xs:w-16 sm:h-[4.5rem] sm:w-[4.5rem] md:h-20 md:w-20 lg:h-20 lg:w-20 ${
                    feed.coinVisibility ? 'opacity-1' : 'opacity-0'
                  } `}
                  onClick={(e) => e.stopPropagation()}
                >
                  {feed.balance > 0 && feed.isClicked ? (
                    <>
                      {feed.showHelp && (
                        <div className='absolute bottom:[-20%] md:bottom-[95%] right-[-70%] md:right-[-100%] flex sm:flex-col justify-center items-center whitespace-nowrap'>
                          <p className='hidden md:block'>Drag Coin To Feeder</p>
                          <div className='mt-2 text-center animate-horizontal-bounce'>
                            <FatRightArrow size={30} fill={'#fff'} />
                          </div>
                        </div>
                      )}
                      <img
                        ref={feed.coinRef}
                        src={randomImage}
                        className={` absolute z-10 top-[0] left-[0] xs:h-16 xs:w-16 sm:h-[4.5rem] sm:w-[4.5rem] md:h-20 md:w-20  lg:h-20 lg:w-20 `}
                        alt='coin'
                        id='draggable'
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='relative'>
            <div
              id='dropzone'
              className='droppable absolute bottom-[-5%] right-[20%]  md:bottom-[0]'
            >
              <img
                ref={feed.candyMachineRef}
                src={CoinMachine}
                id='candy-machine'
                className='xs:h-40 sm:h-44 md:h-48 lg:h-52 '
                alt='Coin Machine'
              ></img>
            </div>
          </div>
        </div>
        <div className='buttonContainer flex justify-center items-center'>
          <Link
            to='https://live.dashducks.tv'
            target='_blank'
            className='text-[#516BF2] font-bold text-base lg:text-xl hover:underline absolute bottom-4'
          >
            DashDucks Livestream
          </Link>
        </div>
      </div>
    </>
  );
};

export default FeedingCard;

import { gql } from '@apollo/client';

export const GET_JWT = gql`
  mutation MyMutation($oauthcode: String!) {
    authenticate(input: { oauthCode: $oauthcode }) {
      jwtToken
      clientMutationId
    }
  }
`;

export const RENEW_JWT = gql`
  mutation authenticateRenew {
    authenticateRenew(input: {}) {
      clientMutationId
      jwtToken
    }
  }
`;

import { createContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { RENEW_JWT } from '../graphql/mutations/jwtAuth';
import { useUser } from '../Context/UserContext';
import jwt_decode from 'jwt-decode';

export const JWTValidationContext = createContext();

const JWTValidationProvider = ({ children }) => {
  const user = useUser();

  // eslint-disable-next-line no-unused-vars
  const [renewJWT, { data, error, loading }] = useMutation(RENEW_JWT, {
    context: {
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    },
  });

  const checkTokenValidity = (token) => {
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken) {
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          localStorage.removeItem('token');
          user.setUser(false);
        } else {
          renewJWT()
            .then(({ data }) => {
              localStorage.setItem('token', data.authenticateRenew.jwtToken);
              user.setUser(true);
              user.setToken(data.authenticateRenew.jwtToken);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        user.setUser(false);
      }
    }
  };

  useEffect(() => {
    // Check token validity on component mount
    checkTokenValidity(user.token);

    // Periodically check token validity every 5 minutes (adjust as needed)
    const interval = setInterval(
      () => checkTokenValidity(user.token),
      30 * 60 * 1000
    );

    // Clean up the interval on component unmount
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user]);

  if (loading) return <pre>loading....</pre>;
  if (error) {
    localStorage.removeItem('token');
  }

  return (
    <JWTValidationContext.Provider value={{ checkTokenValidity }}>
      {children}
    </JWTValidationContext.Provider>
  );
};

export default JWTValidationProvider;

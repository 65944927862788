import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { HATCH_A_PROGRESS_REWARD } from '../graphql/mutations/operations';
import { toast } from 'react-toastify';
import { useProgressDuckling } from '../Context/ProgressDucklingContext';

const ProgressTile = ({ progressReward, isActive, isHatched, isEmpty }) => {
  const [hatched, setHatched] = useState(isHatched);
  // console.log(isActive, isHatched, isEmpty);

  const { setProgressRewardsHatched } = useProgressDuckling();

  const [hatchAProgressRewardFunc] = useMutation(HATCH_A_PROGRESS_REWARD);

  const hatchAProgressReward = async () => {
    if (!isHatched) {
      try {
        const { data } = await hatchAProgressRewardFunc({
          variables: { rewardID: progressReward.id },
        });
        if (data) {
          const newHatchedObj = {
            ...progressReward,
            hatchRewardId: progressReward.id,
          };
          // console.log(progressRewardsHatched, newHatchedObj);
          setProgressRewardsHatched((prev) => [...prev, newHatchedObj]);
          setHatched(true);
          toast.success('Progress hatched');
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          return;
        } else {
          console.error('Error:', error);
        }
      }
    } else {
      toast.warn('Already hatched');
    }
  };

  const progressHatchClickHandler = () => {
    if (isActive) {
      hatchAProgressReward();
    } else {
      toast.error(`Not enough tokens to hatch`);
    }
  };

  return (
    <article
      className='relative cursor-pointer flex flex-col justify-center items-center'
      onClick={() => progressHatchClickHandler()}
    >
      <img
        src={
          'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/331997d1-9c79-4588-9651-3d3576a56700/thumbnail'
        }
        alt='nest'
        className='object-contain xs:min-w-full  sm:max-w-[320px] md:max-w-[256px]  '
      />

      <div className='flex justify-center items-end absolute m-4'>
        {isEmpty ? (
          <></>
        ) : hatched || isHatched ? (
          <img
            src={progressReward.img}
            alt={'Hatched Reward'}
            className='object-contain'
          />
        ) : (
          <img
            src={
              'https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/3159a10e-91c2-4263-185b-fe55ac870d00/thumbnail'
            }
            alt={'Reward Egg'}
            className={`object-contain ${isActive ? 'hatchable-egg' : ''}`}
          />
        )}
      </div>
      <div>
        {progressReward.tokens ? (
          <h4 className='text-center font-bold  xs:text-lg sm:text-lg md:text-xl lg:text-2xl'>
            {progressReward.tokens} Tokens
          </h4>
        ) : (
          // <h4 className='text-center font-bold  xs:text-lg sm:text-lg md:text-xl lg:text-2xl'>
          //   {'Coming Soon '}
          // </h4>
          <div className='h-[1.125rem] md:h-5 ld:h-6 m-[0.35rem]'></div>
        )}
      </div>
    </article>
  );
};

export default ProgressTile;

import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useUser } from '../Context/UserContext';
import loginURL from '../utilities/loginURL';
import CardButton from './CardButton';

const MysteryCard = ({ mysteryData, waitTime, isGuessed }) => {
  const { id, vidNumber, thumbnail, title, link } = mysteryData;

  const user = useUser();
  const navigate = useNavigate();

  const SolveClickHandler = (item) => {
    user.setvideoMysteries(item);
    navigate(`/mysteries/solve/${item.id}`);
  };

  const shareMysteryClickHandler = (item) => {
    user.setvideoMysteries(item);
    navigate(`/share-mystery/${item.id}`);
  };

  const getSharedStatus = () => {
    if (user.sharedMysteries.length > 0) {
      const index = user.sharedMysteries.findIndex(
        (mystery) => mystery.videoMysteryId === id
      );
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getSharedStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.sharedMysteries]);

  return (
    <div className=' bg-cardBg rounded-lg pt-2 px-4 pb-8 max-w-4xl'>
      <div className='font-bold  text-right  text-lg'>#{vidNumber}</div>
      <div className='grid md:grid-cols-[260px_1fr] justify-center p-2 place-content-center'>
        <div className='mx-auto my-auto'>
          <img
            className='object-contain border-4 rounded border-borderColor max-w-[240px]'
            src={thumbnail}
            alt='Solution '
          />
        </div>
        <div className='grid grid-col gap-2 md:gap-4 mt-4 md:ml-4 md:my-4'>
          <div className=' font-bold text-center text-xl md:text-2xl lg:text-3xl max-w-xl'>
            {title}
          </div>
          <div className='flex flex-col justify-between items-center gap-4'>
            {!user.user ? (
              <>
                {/* When user is not logged in */}

                <CardButton title='Watch Video' link={link} bg='bg-[#1D6CB6]' />

                <h4 className='font-bold text-xl text-center'>
                  <Link
                    to={loginURL}
                    className='text-[#516BF2] font-bold hover:underline cursor-pointer'
                  >
                    Log In
                  </Link>{' '}
                  to Solve
                </h4>
              </>
            ) : (
              <>
                {/* When user is logged in */}
                {waitTime.hours === 0 && waitTime.minutes === 0 ? (
                  <>
                    {/*User is logged in and Timer is off */}
                    {isGuessed ? (
                      <>
                        {/* User is logged in, Timer is off and Mystery is guessed */}

                        {!getSharedStatus() && (
                          <div className='my-2 text-center'>
                            <button
                              className='bg-green  rounded-xl font-bold text-base px-4 py-2 lg:text-lg md:px-8 md:py-3 my-2'
                              onClick={() => {
                                shareMysteryClickHandler(mysteryData);
                              }}
                            >
                              +5 Bonus
                            </button>
                          </div>
                        )}

                        <div className='flex flex-wrap items-center justify-center gap-4'>
                          <CardButton
                            title='Mystery Video'
                            link={link}
                            bg='bg-[#746F3B]'
                          />
                          {/* {videoMysterySolutions.edges && (
                            <CardButton
                              title='Solution Video'
                              link={videoMysterySolutions.edges[0].link}
                              bg='bg-[#746F3B]'
                            />
                          )} */}
                        </div>

                        <div className='my-2 text-center'>
                          <p className=' text-center font-bold px-12 py-2 text-lg lg:px-16 '>
                            You've solved this
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* User is logged in, Timer is off and Mystery is NOT guessed */}

                        <CardButton
                          title='Watch Video'
                          link={link}
                          bg='bg-[#1D6CB6]'
                        />

                        <button
                          className='bg-green  font-bold rounded-xl px-14 py-4 text-lg lg:text-2xl lg:px-20 '
                          onClick={() => {
                            SolveClickHandler(mysteryData);
                          }}
                        >
                          Solve
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/*User is logged in and Timer is on */}
                    {isGuessed ? (
                      <>
                        {/* User is logged in, Timer is ON and Mystery is guessed */}

                        <div className='flex flex-wrap items-center justify-center gap-4'>
                          <CardButton
                            title='Mystery Video'
                            link={link}
                            bg='bg-[#746F3B]'
                          />
                          {/* {videoMysterySolutions.edges && (
                            <CardButton
                              title='Solution Video'
                              link={videoMysterySolutions.edges[0].link}
                              bg='bg-[#746F3B]'
                            />
                          )} */}
                        </div>

                        <div className='my-2 text-center'>
                          <p className=' text-center font-bold px-12 py-2 text-lg lg:px-16 '>
                            You've solved this
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* User is logged in, Timer is ON and Mystery is NOT guessed */}

                        <CardButton
                          title='Watch Video'
                          link={link}
                          bg='bg-[#1D6CB6]'
                        />

                        <p className=' text-center font-bold px-12 py-2 text-lg  lg:px-16'>
                          You may solve in {waitTime.hours}hrs and{' '}
                          {waitTime.minutes}mins
                        </p>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MysteryCard;

import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  GET_ALL_RAIDS_USER,
  GET_ALL_RAIDS_PUBLIC,
} from '../graphql/queries/operations';
import RaidListItem from '../Components/RaidListItem';
import { useUser } from '../Context/UserContext';
import { ReloadIcon } from '../utilities/SVGIcons';

const RaidRewardsPage = () => {
  const [raidList, setRaidList] = useState([]);
  const [claimedRaids, setClaimedRaids] = useState([]);
  const [offset, setOffset] = useState(0);
  const [endOfData, setEndOfData] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const user = useUser();

  const [getRaidsForUserFunc, { refetch: userQueryRefetch }] =
    useLazyQuery(GET_ALL_RAIDS_USER);
  const [getRaidsForPublicFunc, { refetch: publicQueryRefetch }] =
    useLazyQuery(GET_ALL_RAIDS_PUBLIC);

  const getRaidsForUser = async () => {
    try {
      const { data } = await getRaidsForUserFunc({
        variables: { offset: offset },
      });
      if (data) {
        const newRaidData = data.raids?.nodes || [];
        setRaidList((prev) => removeDuplicateData(newRaidData, prev));
        setClaimedRaids((prev) => [...prev, ...data.raidClaims?.nodes]);

        if (!data.raids?.pageInfo.hasNextPage) {
          setEndOfData(true);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const getRaidsForPublic = async () => {
    try {
      const { data } = await getRaidsForPublicFunc({
        variables: { offset: offset },
      });

      if (data) {
        const newRaidData = data.raids?.nodes || [];
        setRaidList((prev) => removeDuplicateData(newRaidData, prev));
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const removeDuplicateData = (newRaidData, prev, addToStart = false) => {
    const existingRaids = new Set(prev.map((item) => item.id)); //creates unique array based on assumption that id will be unique for each raid

    const updatedRaidList = newRaidData.reduce(
      (acc, item) => {
        if (!existingRaids.has(item.id)) {
          existingRaids.add(item.id);
          if (addToStart) {
            acc.unshift(item);
          } else {
            acc.push(item);
          }
        }
        return acc;
      },
      [...prev]
    );

    const sortedRaidList = updatedRaidList.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    if (!newRaidData.length) {
      setEndOfData(true);
    }
    return sortedRaidList;
  };

  const refreshRaidData = async () => {
    if (isRefreshing) {
      return; // Prevent multiple simultaneous refresh operations
    }

    setIsRefreshing(true);
    try {
      let raids;
      if (user.user) {
        const { data } = await userQueryRefetch({ offset: 0 });
        raids = data.raids;
      } else {
        const { data } = await publicQueryRefetch({ offset: 0 });
        raids = data.raids;
      }

      // console.log('reftched raids data : ', raids);

      if (raids) {
        const newTransaction = raids.nodes;
        // console.log(newTransaction);
        if (newTransaction) {
          setRaidList((prev) =>
            removeDuplicateData(newTransaction, prev, true)
          );
        }
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    if (user.user) {
      getRaidsForUser();
    } else {
      getRaidsForPublic();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user, offset]);

  return (
    <div className='relative flex flex-col h-full w-9/12 max-w-4xl  mx-auto  py-10 '>
      <div className='bg-cardBg rounded-lg grid-flow-rows w-full h-max-screen mx-auto mb-3'>
        <div className='relative'>
          <div className='text-center  font-bold  xs:text-xl xs:py-6 sm:text-xl md:text-2xl lg:text-3xl lg:py-8 lg:mb-3'>
            Raid Rewards
          </div>
          <div className='absolute top-[35%] right-8'>
            <button
              className={`cursor-pointer ${isRefreshing ? 'animate-spin' : ''}`}
              onClick={refreshRaidData}
            >
              <ReloadIcon size='1.5rem' fill='#fff' />
            </button>
          </div>
        </div>

        <ul>
          {raidList.length ? (
            raidList.map((raid, index) => (
              <RaidListItem
                key={raid.id}
                raid={raid}
                index={index}
                claimedStatus={claimedRaids?.some(
                  (cRaids) => cRaids.raidId === raid.id
                )}
              />
            ))
          ) : (
            <div className='bg-[#464646] bg-opacity-50 text-center font-bold text-sm md:text-lg lg:text-xl py-2'>
              No Raid Data
            </div>
          )}
          {raidList && !endOfData && (
            <div className='w-full mx-auto text-center m-5'>
              <button
                className='bg-green font-bold rounded-xl py-1 px-2 '
                onClick={() => setOffset((prev) => prev + 10)}
              >
                Load More
              </button>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RaidRewardsPage;

import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ALL_DAILY_PHOTOS } from '../graphql/queries/operations';
import { useUser } from '../Context/UserContext';
import DailyPhotoCard from '../Components/DailyPhotoCard';
import { RESHARE_PHOTO_TIMER } from '../graphql/mutations/operations';

const DailyPhotosListingPage = () => {
  const [dailyPhotosList, setDailyPhotosList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [endOfData, setEndOFData] = useState(false);
  const [waitTime, setWaitTime] = useState({ hours: 0, minutes: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const user = useUser();

  const [getAllDailyPhotosFunc] = useLazyQuery(ALL_DAILY_PHOTOS);

  const [getShareTimerFunc] = useMutation(RESHARE_PHOTO_TIMER);

  const getAllDailyPhotos = async (offset) => {
    try {
      setIsLoading(true);
      const { data } = await getAllDailyPhotosFunc({
        variables: { offset: offset },
      });
      setDailyPhotosList((prev) => [...prev, ...data.photos?.nodes]);
      setIsLoading(false);
      if (!data.photos?.pageInfo?.hasNextPage) {
        setEndOFData(true);
      }
      if (data && user.user) {
        try {
          const { data: time } = await getShareTimerFunc();
          setWaitTime({
            hours: time.photoCanShareIn.results[0].hoursUntilShare,
            minutes: time.photoCanShareIn.results[0].minutesUntilShare,
          });
        } catch (error) {
          console.error('Error in getting time:', error);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    getAllDailyPhotos(offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getShareTimerFunc()
        .then(({ data: time }) =>
          setWaitTime({
            hours: time.photoCanShareIn.results[0].hoursUntilShare,
            minutes: time.photoCanShareIn.results[0].minutesUntilShare,
          })
        )
        .catch((error) => console.error(error));
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId);
    };
  }, [getShareTimerFunc]);
  return (
    <>
      <div className='bg-bg h-full py-8 '>
        {isLoading ? (
          <div className='flex justify-center items-center w-full h-[80vh] '>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='scroll-smooth grid place-content-center text-white gap-4 mx-4'>
            {dailyPhotosList &&
              dailyPhotosList.map((photo, index) => (
                <DailyPhotoCard
                  key={photo.id}
                  photoData={photo}
                  waitTime={waitTime}
                  index={index}
                />
              ))}
            {dailyPhotosList.length && !endOfData && (
              <div className='w-full mx-auto text-center m-5'>
                <button
                  className='bg-green font-bold rounded-xl py-1 px-2 '
                  onClick={() => setOffset((prev) => prev + 10)}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DailyPhotosListingPage;

const formatDate = (timestamp, options = {}) => {
  const dateObject = new Date(timestamp);

  const defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const formatOptions = { ...defaultOptions, ...options };

  const formattedDate = new Intl.DateTimeFormat('en-US', formatOptions).format(
    dateObject
  );

  if (options.time) {
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(dateObject);

    return `${formattedDate} ${formattedTime}`;
  }

  return formattedDate;
};

export default formatDate;

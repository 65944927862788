import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UserProvider } from './Context/UserContext';
import { client } from './utilities/ApolloClientSetup';
import JWTValidationProvider from './Components/JWTValidationProvider';
// import HomePage from './pages/HomePage';
import HomePagev2 from './pages/HomePagev2';
import TwitchOAuth from './Components/TwitchOAuth';
import Logout from './Components/Logout';
import ResultPage from './pages/ResultPage';
import Menubar from './Components/Menubar';
import FeedPage from './pages/FeedPage';
import AllMysteriesListingPage from './pages/AllMysteriesListingPage';
import SolveMysteryPage from './pages/SolveMysteryPage';
import DailyPhotosListingPage from './pages/DailyPhotosListingPage';
import DailyPhotoDetailsPage from './pages/DailyPhotoDetailsPage';
import ShareMysteryPage from './pages/ShareMysteryPage';
import RaidRewardsPage from './pages/RaidRewardsPage';
import ThankYouPage from './pages/ThankYouPage';
import ProgressPage from './pages/ProgressPage';
import { ProgressDucklingContextProvider } from './Context/ProgressDucklingContext';

function App() {
  return (
    <>
      <Router>
        <UserProvider>
          <ApolloProvider client={client}>
            <JWTValidationProvider>
              <ProgressDucklingContextProvider>
                <Menubar />
                <Routes>
                  <Route path='/' element={<HomePagev2 />}></Route>
                  <Route path='/twitch-login' element={<TwitchOAuth />}></Route>
                  <Route
                    path='/photos'
                    element={<DailyPhotosListingPage />}
                  ></Route>
                  <Route
                    path='/photo/:queryId'
                    element={<DailyPhotoDetailsPage />}
                  />
                  <Route
                    path='/daily-photo/:queryId'
                    element={<DailyPhotoDetailsPage />}
                  />
                  <Route
                    path='/mysteries'
                    element={<AllMysteriesListingPage />}
                  ></Route>
                  <Route
                    path='/mysteries/solve/:nodeId'
                    element={<SolveMysteryPage />}
                  ></Route>
                  <Route
                    path='/mysteries/result'
                    element={<ResultPage />}
                  ></Route>
                  <Route path='/feed' element={<FeedPage />} />
                  <Route
                    path='/share-mystery/:mysteryId'
                    element={<ShareMysteryPage />}
                  />
                  <Route path='/progress' element={<ProgressPage />} />
                  <Route path='/raids' element={<RaidRewardsPage />} />
                  <Route path='/thank-you' element={<ThankYouPage />} />
                  <Route path='/logout' element={<Logout />}></Route>
                </Routes>
              </ProgressDucklingContextProvider>
            </JWTValidationProvider>
          </ApolloProvider>
        </UserProvider>
      </Router>
      <ToastContainer
        transition={Slide}
        position='top-right'
        autoClose={3500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  );
}

export default App;

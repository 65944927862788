import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useUser } from '../Context/UserContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const user = useUser();

  //assigning environmental variable to local variable
  const clientid = process.env.REACT_APP_CLIENT_ID;
  const redirectURI = process.env.REACT_APP_REDIRECT_URI;

  //To start oAuth process, logging to twitch account
  const url = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${clientid}&redirect_uri=${redirectURI}&scope=user%3Aread%3Aemail&state=12345`;

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center gap-x-1.5  rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 shadow-sm  '>
          <i
            className='fa fa-bars '
            style={{ fontSize: '20px' }}
            aria-hidden='true'
          ></i>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-40 z-50 origin-top-right  bg-navbarBg shadow-lg  focus:outline-none'>
          <div className='py-1'>
            {user.user ? (
              <>
                <button className='hover:bg-hoverBg w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/mysteries'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Solve Mysteries
                      </Link>
                    )}
                  </Menu.Item>
                </button>

                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/photos'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Photos
                      </Link>
                    )}
                  </Menu.Item>
                </button>

                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/raids'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Raid Rewards
                      </Link>
                    )}
                  </Menu.Item>
                </button>

                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/feed'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Feed the Ducks
                      </Link>
                    )}
                  </Menu.Item>
                </button>

                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/progress'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Ducklings
                      </Link>
                    )}
                  </Menu.Item>
                </button>

                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/logout'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm '
                        )}
                      >
                        Log Out
                      </Link>
                    )}
                  </Menu.Item>
                </button>
              </>
            ) : (
              <>
                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/mysteries'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Solve Mysteries
                      </Link>
                    )}
                  </Menu.Item>
                </button>
                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/photos'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Photos
                      </Link>
                    )}
                  </Menu.Item>
                </button>
                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to='/raids'
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm hover:bg-hoverBg rounded-md'
                        )}
                      >
                        Raid Rewards
                      </Link>
                    )}
                  </Menu.Item>
                </button>
                <button className='hover:bg-hoverBg  w-40'>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={url}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm '
                        )}
                      >
                        Log in
                      </Link>
                    )}
                  </Menu.Item>
                </button>
              </>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

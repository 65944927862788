const dragTouchElement = (
  element,
  dragzone,
  originalTop,
  originalLeft,
  dropArea,
  setdropArea,
  candyMachineRef,
  setcoinDropped
) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  // Touch actions
  const dragTouchEnd = (event) => {
    event.preventDefault();
    // Check if the coin is at the candy machine
    if (candyMachineRef.current == null) return; // avoid error when showing animation

    const rect = candyMachineRef.current.getBoundingClientRect();
    const touch = event.changedTouches[0];
    const touchX = touch.clientX;
    const touchY = touch.clientY;

    if (
      touchX >= rect.left &&
      touchX <= rect.right &&
      touchY >= rect.top &&
      touchY <= rect.bottom
    ) {
      setdropArea(true);
    } else {
      setdropArea(false);
    }

    if (dropArea === false) {
      document.ontouchmove = null;
      document.ontouchend = null;
      element.classList.remove('drag');

      element.style.top = originalTop;
      element.style.left = originalLeft;
    }

    if (dropArea === true) {
      document.ontouchmove = null;
      document.ontouchend = null;
      element.classList.remove('drag');

      element.style.top = originalTop;
      element.style.left = originalLeft;

      setTimeout(() => {
        setcoinDropped(false);
      }, 3000);
    }
  };

  const dragTouchMove = (event) => {
    // Drag coin
    const touch = event.changedTouches[0];
    pos1 = pos3 - touch.clientX;
    pos2 = pos4 - touch.clientY;
    pos3 = touch.clientX;
    pos4 = touch.clientY;

    let currentPosX = `${element.offsetTop - pos2}px`;
    let currentPosY = `${element.offsetLeft - pos1}px`;

    element.style.top = currentPosX;
    element.style.left = currentPosY;
  };

  const dragTouchStart = (event) => {
    event.preventDefault();

    const touch = event.touches[0];
    pos3 = touch.clientX;
    pos4 = touch.clientY;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    originalTop = element.getBoundingClientRect().top;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    originalLeft = element.getBoundingClientRect().left;
    element.classList.add('drag');

    document.ontouchend = dragTouchEnd;
    document.ontouchmove = dragTouchMove;
  };

  element.ontouchstart = dragTouchStart;
};

export default dragTouchElement;

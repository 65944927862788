import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { PHOTO_DETAILS } from '../graphql/queries/operations';

const DailyPhotoDetailsPage = () => {
  const { queryId } = useParams();

  const {
    data: photoData,
    loading,
    error,
  } = useQuery(PHOTO_DETAILS, { variables: { id: queryId } });

  if (loading) {
    return <p>loading...</p>;
  } else if (error) {
    <div className='bg-bg h-full py-8 '>
      <div className='scroll-smooth grid place-content-center text-white gap-4 mx-4'>
        <div className=' bg-cardBg rounded-lg pt-2 px-4 pb-8 max-w-4xl'>
          <h2>Unable to get daily photo</h2>
        </div>
      </div>
    </div>;
  }
  return (
    <>
      <div className='bg-bg h-full py-8 '>
        <div className='scroll-smooth grid place-content-center text-white gap-4 mx-4'>
          {photoData && (
            <div className=' bg-cardBg rounded-lg pt-2 px-4 pb-4 w-4xl'>
              {/* <div className='font-bold text-right  text-lg'>
                {formatDate(photoData.photo.createdAt)}
              </div> */}
              <div className='flex flex-col justify-center items-center md:flex-row md:justify-around  gap-4 p-4'>
                <div className='max-w-lg m-0'>
                  <p className='text-center'>{photoData.photo.title}</p>
                </div>
                <Link to='https://live.dashducks.tv' target='_blank'>
                  <button className='bg-blue font-bold rounded-xl px-12 py-2 text-lg  lg:px-16 '>
                    Feed the Ducks
                  </button>
                </Link>
              </div>
              <div className='flex justify-center items-center p-2'>
                <img
                  src={photoData.photo.large}
                  alt='Daily Duck'
                  className='object-contain border-4 rounded border-borderColor max-w-[80vw] max-h-screen'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DailyPhotoDetailsPage;

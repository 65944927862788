import React from 'react';
// import { MdClose, MdDownload } from 'react-icons/md';

const FullscreenImageModal = ({ image, setViewLargeImage }) => {
  // const handleDownloadClick = async () => {
  //   try {
  //     const response = await fetch(image);
  //     const blob = await response.blob();

  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'image';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     // Release the blob URL
  //     URL.revokeObjectURL(link.href);
  //   } catch (error) {
  //     console.error('Error downloading image:', error);
  //   }
  // };

  return (
    <div
      className='fixed inset-0 flex justify-center items-center z-50'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
      onClick={() => setViewLargeImage(false)}
    >
      <div
        className='flex flex-col justify-center md:justify-center items-center'
        onClick={() => setViewLargeImage(false)}
      >
        {/* <div className='max-w-full max-h-full md:w-10/12 p-4 flex justify-end'>
          <button onClick={handleDownloadClick}>
            <MdDownload className='text-3xl text-gray-700' />
          </button>
          <button onClick={() => setViewLargeImage(false)}>
            <MdClose className='text-3xl text-gray-700' />
          </button>
        </div> */}
        <img
          className='max-w-full max-h-screen object-contain justify-center'
          src={image}
          alt='large'
        />
      </div>
    </div>
  );
};

export default FullscreenImageModal;

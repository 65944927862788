import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_JWT } from '../graphql/mutations/jwtAuth';
import { useMutation } from '@apollo/client';

import { useUser } from '../Context/UserContext';

const TwitchOAuth = () => {
  const navigate = useNavigate();
  const user = useUser();

  //Extracting code parameter from the redirect URL
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get('code');
  console.log('code :', code, ' state:', queryParameters.get('state'));

  const [getJWT, { error, loading }] = useMutation(GET_JWT, {
    variables: {
      oauthcode: code,
    },
  });

  useEffect(() => {
    if (code) {
      getJWT()
        .then(({ data }) => {
          if (data.authenticate.jwtToken === null) {
            user.setUser(false);
            navigate('/');
          } else {
            localStorage.setItem('token', data.authenticate.jwtToken);
            user.setUser(true);
            user.setToken(data.authenticate.jwtToken);
            navigate('/mysteries');
          }
        })
        .catch((error) => console.log('JWT error', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (loading)
    return (
      <div className='flex justify-center items-center w-full h-[80vh] '>
        <div className='loader'></div>
      </div>
    );
  if (error) {
    console.log('error ->', error);
    user.setUser(false);
    navigate('/');
  }

  return <div>JWT TOKEN</div>;
};

export default TwitchOAuth;

const dragElement = (
  element,
  dragzone,
  originalTop,
  originalLeft,
  dropArea,
  setdropArea,
  candyMachineRef
) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  //mouse actions
  const dragMouseUp = (event) => {
    // check if coin is at machine
    event.preventDefault();
    if (candyMachineRef.current == null) return; // avoid error when showing animation

    const rect = candyMachineRef.current.getBoundingClientRect();
    if (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    ) {
      setdropArea(true);
    } else {
      setdropArea(false);
    }

    if (dropArea === false) {
      document.onmousemove = null;
      document.onmouseup = null;
      element.classList.remove('drag');
    }

    if (dropArea === true) {
      document.onmousemove = null;
      document.onmouseup = null;
      element.classList.remove('drag');
    }
  };

  const dragMouseMove = (event) => {
    event.preventDefault();

    // drag coin
    pos1 = pos3 - event.clientX;
    pos2 = pos4 - event.clientY;
    pos3 = event.clientX;
    pos4 = event.clientY;

    let currentPosX = `${element.offsetTop - pos2}px`;
    let currentPosY = `${element.offsetLeft - pos1}px`;

    element.style.top = currentPosX;
    element.style.left = currentPosY;
  };

  const dragMouseOut = () => {
    element.style.top = originalTop;
    element.style.left = originalLeft;
  };

  const dragMouseDown = (event) => {
    event.preventDefault();

    pos3 = event.clientX;
    pos4 = event.clientY;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    originalTop = element.getBoundingClientRect().top;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    originalLeft = element.getBoundingClientRect().left;
    element.classList.add('drag');

    document.onmouseup = dragMouseUp;
    document.onmousemove = dragMouseMove;
  };

  dragzone.onmousedown = dragMouseDown;
  dragzone.onmouseout = dragMouseOut;
};

export default dragElement;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from '../Context/UserContext';

export default function Result() {
  const user = useUser();

  useEffect(() => {
    if (user.token && user.user) {
      return;
    }
  }, [user]);

  return (
    <div className='bg-bg h-full pt-8 md:pt-10 '>
      <div className=' grid  place-content-center max-w-max mx-auto xs:px-4  xs:py-auto md:px-10'>
        <div className='card bg-cardBg rounded-lg justify-items-stretch max-w-4xl'>
          <div className='grid grid-cols-1 place-content-center text-center lg:p-4'>
            <div className='font-bold text-xl lg:text-3xl mt-8 mx-8'>
              Thank you for your answer. You have received your reward tokens!
            </div>
            {/* <div className='my-8'>
              <button className='bg-green  font-bold rounded-xl px-10 py-3 lg:px-16 lg:py-3 lg:text-2xl place-content-end'>
                <Link to={link} target='_blank'>
                  Solution Video
                </Link>
              </button>
            </div> */}
            <div className=' text-center my-8'>
              <button className='bg-[#1D6CB6]  font-bold rounded-xl lg:px-8 lg:py-2 lg:text-base xs:px-2 py-2 text-xs'>
                <Link to={'/feed'}>Feed the Ducks</Link>
              </button>
            </div>

            <div className='justify-item-center justify-content-center mx-auto lg:pt-2 w-7/12 mb-8'>
              <img
                src='https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/e17d3674-1b69-41f9-1e4c-0f51f3f4e600/thumbnail'
                alt='thumbsUpDuck'
                className='mx-auto'
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { FeedingContextProvider } from '../Context/FeedingContext';
import FeedingCard from '../Components/FeedingCard';
import FeedingHistory from '../Components/FeedingHistory';

import '../styles/Feed.css';

function FeedPage() {
  return (
    <>
      <FeedingContextProvider>
        <div className='relative feedContainer flex flex-col h-full xs:w-11/12 md:w-9/12 max-w-4xl  mx-auto  py-8 '>
          <FeedingCard />
          <FeedingHistory />
        </div>
      </FeedingContextProvider>
    </>
  );
}

export default FeedPage;

import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  GET_MYSTERY_GUESSES,
  SHARED_MYSTERIES,
  GET_MYSTERIES_LIST,
} from '../graphql/queries/operations';
import MysteryCard from '../Components/MysteryCard';
import { useUser } from '../Context/UserContext';
import { GET_SHARE_MYSTERY_TIMER } from '../graphql/mutations/operations';

const AllMysteriesListingPage = () => {
  const [mysteriesData, setMysteriesData] = useState([]);

  const [waitTime, setWaitTime] = useState({ hours: 0, minutes: 0 });

  const [isLoading, setIsLoading] = useState(true);

  const user = useUser();

  const [getMysteryDetailsFunc] = useLazyQuery(GET_MYSTERIES_LIST);
  const [getMysteryGuessesFunc] = useLazyQuery(GET_MYSTERY_GUESSES);
  const [getSharedMysteriesFunc] = useLazyQuery(SHARED_MYSTERIES);
  const [getShareMysteryTimerFunc] = useMutation(GET_SHARE_MYSTERY_TIMER);

  const getMysteryDetails = async () => {
    try {
      const { data } = await getMysteryDetailsFunc();
      setMysteriesData([...data.videoMysteries?.edges]);
      setIsLoading(false);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error in getting mystery details : ', error);
      }
    }
  };

  const getMysteryGuesses = async () => {
    try {
      const { data } = await getMysteryGuessesFunc();
      user.setMysteryGuesses((prev) => [
        ...prev,
        ...data.videoMysteryGuesses?.nodes,
      ]);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error in getting mystery guesses : ', error);
      }
    }
  };

  useEffect(() => {
    if (user.user) getMysteryGuesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitTime]);

  const getSharedMysteries = async () => {
    try {
      const { data } = await getSharedMysteriesFunc();
      user.setSharedMysteries([...data.videoMysteryShares?.nodes]);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const getMysteryShareTimer = async () => {
    try {
      const { data: time } = await getShareMysteryTimerFunc();
      setWaitTime({
        hours: time.canEarnIn?.results[0].hoursUntilShare,
        minutes: time.canEarnIn?.results[0].minutesUntilShare,
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    if (user.token && user.user) {
      return;
    }
  }, [user]);

  useEffect(() => {
    getMysteryDetails();
    if (user.user) {
      getMysteryGuesses();
      getSharedMysteries();
      getMysteryShareTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // optimize by setting time on individual card , tradeOf: api call on every card
    const intervalId = setInterval(() => {
      getShareMysteryTimerFunc()
        .then(({ data: time }) =>
          setWaitTime({
            hours: time.canEarnIn?.results[0].hoursUntilShare,
            minutes: time.canEarnIn?.results[0].minutesUntilShare,
          })
        )
        .catch((error) => {
          if (error.name === 'AbortError') {
            return;
          } else {
            console.error('Error:', error);
          }
        });
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId);
    };
  }, [getShareMysteryTimerFunc]);

  return (
    <>
      <div className='bg-bg h-full py-8 '>
        {isLoading ? (
          <div className='flex justify-center items-center w-full h-[80vh] '>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='scroll-smooth grid place-content-center text-white gap-4 mx-4'>
            {mysteriesData
              ? mysteriesData.map(({ node }) => (
                  <MysteryCard
                    key={node.id}
                    mysteryData={node}
                    waitTime={waitTime}
                    isGuessed={user?.mysteryGuesses?.some(
                      (guess) => guess.videoMysteryId === node.id
                    )}
                  />
                ))
              : null}
          </div>
        )}
      </div>
    </>
  );
};

export default AllMysteriesListingPage;

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

import { useUser } from '../Context/UserContext';
import FullscreenImageModal from './FullscreenImageModal';
import { SHARE_PHOTO } from '../graphql/mutations/operations';
import { SHARED_PHOTOS } from '../graphql/queries/operations';
// import formatDate from '../utilities/formatDate';
import loginURL from '../utilities/loginURL';

const DailyPhotoCard = ({ photoData, waitTime, index }) => {
  const [sharedStatus, setSharedStatus] = useState(false);
  const [photoShared, setPhotoShared] = useState(false);
  const [viewLargeImage, setViewLargeImage] = useState(false);

  const [isShareBtnClicked, setIsShareBtnClicked] = useState(false);

  const navigate = useNavigate();

  const { id, title, thumbnail, large } = photoData;

  const user = useUser();

  const shareLinksData = {
    url: `https://drscovy.com/photo/${id}`,
    title: 'Feed the ducks with me: ',
    source: 'https://drscovy.com',
  };

  const [sharePhotoFunc] = useMutation(SHARE_PHOTO);
  const [getSharedPhotosFunc] = useLazyQuery(SHARED_PHOTOS);

  const sharedClickHandler = async () => {
    try {
      const { data } = await sharePhotoFunc({ variables: { photoId: id } });
      if (data) {
        setPhotoShared(true);
        toast.success('Congratulations! You earned 1 feed token');
        navigate('/thank-you');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyHandler = async () => {
    try {
      await navigator.clipboard.writeText(
        `Feed the ducks with me:\n${shareLinksData.url}`
      );
      toast.info('Link copied');
      setIsShareBtnClicked(true);
    } catch (error) {
      console.error('Error copying:', error);
    }
  };

  const getSharedPhotos = async () => {
    try {
      const { data } = await getSharedPhotosFunc({
        variables: { accountId: user.accountId },
      });
      if (data) {
        const photoIndex = data.photoShares.nodes.findIndex(
          (photo) => photo.photoId === id
        );
        if (photoIndex === -1) {
          setSharedStatus(false);
        } else {
          setSharedStatus(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSharedPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoShared]);

  return (
    <>
      {photoData && (
        <div className=' bg-cardBg rounded-lg pt-8 px-4 pb-8 max-w-4xl'>
          {/* <div className='font-bold text-right  text-lg'>
            {formatDate(createdAt)}
          </div> */}
          <div className='grid md:grid-cols-[300px_1fr] justify-center p-2 place-content-center'>
            <div className='flex justify-center items-center cursor-pointer'>
              <img
                className='object-contain border-4 rounded border-borderColor max-w-[275px]'
                src={thumbnail}
                alt='Daily'
                onClick={() => setViewLargeImage(true)}
              />
            </div>

            <div className='grid grid-col gap-2 md:gap-4 mt-4 md:ml-4 md:my-0 px-8'>
              {user.user ? (
                !sharedStatus ? (
                  waitTime.hours === 0 &&
                  waitTime.minutes === 0 && (
                    <div>
                      <h4 className='font-bold text-xl text-center'>
                        Share to get a Token
                      </h4>
                      <div className='flex justify-center items-center gap-2 mt-2'>
                        <FacebookShareButton
                          url={shareLinksData.url}
                          quote={shareLinksData.title}
                          onClick={() => setIsShareBtnClicked(true)}
                        >
                          <FacebookIcon size={36} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={shareLinksData.url}
                          title={shareLinksData.title}
                          onClick={() => setIsShareBtnClicked(true)}
                        >
                          <TwitterIcon size={36} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          url={shareLinksData.url}
                          title={shareLinksData.title}
                          onClick={() => setIsShareBtnClicked(true)}
                        >
                          <WhatsappIcon size={36} />
                        </WhatsappShareButton>
                        <LinkedinShareButton
                          url={shareLinksData.url}
                          title={shareLinksData.title}
                          source={shareLinksData.source}
                          onClick={() => setIsShareBtnClicked(true)}
                        >
                          <LinkedinIcon size={36} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={shareLinksData.url}
                          subject='DashDucks Daily Image'
                          body={shareLinksData.title}
                          onClick={() => setIsShareBtnClicked(true)}
                        >
                          <EmailIcon size={36} />
                        </EmailShareButton>

                        <div
                          className='bg-green p-2 cursor-pointer'
                          defaultValue={`${large} \n\n ${title}`}
                          onClick={copyHandler}
                        >
                          <MdContentCopy size={20} />
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <></>
                )
              ) : (
                index === 0 && (
                  <div className='flex-col justify-around'>
                    <h4 className='font-bold text-xl text-center'>
                      <Link
                        to={loginURL}
                        className='text-[#516BF2] font-bold hover:underline cursor-pointer'
                      >
                        Log In
                      </Link>{' '}
                      to Earn Food Tokens
                    </h4>
                    <div className=' text-center flex justify-center items-end'>
                      <Link to={'https://live.dashducks.tv'} target='_blank'>
                        <button className='bg-blue font-bold rounded-xl px-12 py-2 text-lg  lg:px-16 mt-4 md:mt-8'>
                          Watch the Livestream
                        </button>
                      </Link>
                    </div>
                  </div>
                )
              )}

              <div
                className={`max-w-lg md:px-8 mt-2 md:mt-0 flex justify-center items-center `}
              >
                <p className='text-center'>{title}</p>
              </div>
              {user.user &&
                (!sharedStatus ? (
                  waitTime.hours === 0 && waitTime.minutes === 0 ? (
                    <div
                      className={`text-center ${
                        isShareBtnClicked ? 'visible' : 'invisible'
                      }`}
                    >
                      <button
                        className='bg-green  font-bold rounded-xl px-12 py-2 text-lg  lg:px-16 '
                        onClick={sharedClickHandler}
                      >
                        I've shared
                      </button>
                    </div>
                  ) : (
                    <div className='flex justify-center items-end'>
                      <p className=' text-center font-bold px-12 py-2 text-lg  lg:px-16'>
                        You may share in {waitTime.hours}hrs and{' '}
                        {waitTime.minutes}mins
                      </p>
                    </div>
                  )
                ) : (
                  <div className='flex justify-center items-end'>
                    <p className=' text-center font-bold px-12 py-2 text-lg lg:px-16 '>
                      You shared this
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {viewLargeImage && (
        <FullscreenImageModal
          image={large}
          setViewLargeImage={setViewLargeImage}
        />
      )}
    </>
  );
};

export default DailyPhotoCard;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../Context/UserContext';

function Logout() {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    user.setUser(false);
    user.setToken(null);
    localStorage.clear();
    navigate('/');
  });

  return <div></div>;
}

export default Logout;

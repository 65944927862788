import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from './UserContext';
import {
  GET_ALL_AVAILABLE_PROGRESS_REWARDS,
  PROGRESS_REWARDS_HATCHEDS_BY_USER,
} from '../graphql/queries/operations';
import { TOKENS_EARNED, BALANCE_DATA } from '../graphql/mutations/operations';
const ProgressDucklingContext = createContext();

export const ProgressDucklingContextProvider = ({ children }) => {
  const [allAvailableRewards, setAllAvailableRewards] = useState([]);
  const [tokensEarned, setTokensEarned] = useState(0);
  const [progressRewardsHatched, setProgressRewardsHatched] = useState([]);
  const [balance, setBalance] = useState(0);

  const user = useUser();
  const location = useLocation();

  const [getAllAvailableProgressRewardsFunc] = useLazyQuery(
    GET_ALL_AVAILABLE_PROGRESS_REWARDS
  );
  const [getTokensEarnedFunc] = useMutation(TOKENS_EARNED);
  const [getProgressRewardsHatchedByUserFunc] = useLazyQuery(
    PROGRESS_REWARDS_HATCHEDS_BY_USER
  );
  const [getBalanceDataFunc] = useMutation(BALANCE_DATA);

  const getAllAvailableProgressRewards = async () => {
    try {
      const { data } = await getAllAvailableProgressRewardsFunc();
      if (data) {
        setAllAvailableRewards(data.hatchRewards.nodes);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const getTokensEarned = async () => {
    try {
      const { data } = await getTokensEarnedFunc();
      if (data) {
        setTokensEarned(data.tokensEarned.integer);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const getProgressRewardsHatchedByUser = async () => {
    try {
      const { data } = await getProgressRewardsHatchedByUserFunc();
      if (data.hatchRewardRedeemeds.nodes.length) {
        // console.log(data.hatchRewardRedeemeds.nodes);
        setProgressRewardsHatched(() => [...data.hatchRewardRedeemeds.nodes]);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  const getBalanceData = async () => {
    try {
      const { data } = await getBalanceDataFunc();
      if (data) {
        setBalance(data.balance.integer);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      } else {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/progress') {
      const fetchData = async () => {
        try {
          if (user.user) {
            await getAllAvailableProgressRewards();
            await getBalanceData();
            await getTokensEarned();
            await getProgressRewardsHatchedByUser();
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      fetchData();
      // console.log('PRH --> ', progressRewardsHatched);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ProgressDucklingContext.Provider
      value={{
        allAvailableRewards,
        setAllAvailableRewards,
        tokensEarned,
        setTokensEarned,
        progressRewardsHatched,
        setProgressRewardsHatched,
        balance,
        setBalance,
      }}
    >
      {children}
    </ProgressDucklingContext.Provider>
  );
};

export const useProgressDuckling = () => {
  return useContext(ProgressDucklingContext);
};

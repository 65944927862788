import formatDate from '../utilities/formatDate';
import { useFeed } from '../Context/FeedingContext';

const FeedingHistory = () => {
  const feed = useFeed();

  return (
    <div className='bg-cardBg rounded-lg grid-flow-rows w-full h-max-screen mx-auto mt-10 mb-3'>
      <div className='text-center  font-bold  xs:text-xl xs:py-6 sm:text-xl md:text-2xl lg:text-3xl lg:py-8 lg:mb-3'>
        History
      </div>

      <ul>
        {feed.feedHistory.length ? (
          feed.feedHistory.map((item, index) => (
            <div
              key={index}
              className={`grid grid-cols-[35%,1fr,2rem] justify-items-centre text-white font-bold text-sm md:text-md lg:text-lg px-4 md:px-8 lg:px-10 md:grid-cols[25%,1fr,4rem] lg:grid[20%,1fr,5rem] ${
                index % 2 === 0
                  ? 'bg-[#363636] bg-opacity-50  '
                  : 'bg-[#464646] bg-opacity-50'
              }`}
            >
              <div className='max-w-max my-2'>
                {formatDate(item.createdAt, { time: true })}
              </div>
              <div className='ml-4 my-2'>{item.reason}</div>
              <div className='my-2 text-right'>{item.amount}</div>
            </div>
          ))
        ) : (
          <div className='bg-[#464646] bg-opacity-50 text-center font-bold text-sm md:text-lg lg:text-xl py-2'>
            No Redeem History Yet
          </div>
        )}
        {!feed.endOfData && (
          <div className='w-full mx-auto text-center m-5'>
            <button
              className='bg-green font-bold rounded-xl py-1 px-2 '
              onClick={() => feed.setPageOffset((prev) => prev + 10)}
            >
              Load More
            </button>
          </div>
        )}
      </ul>
    </div>
  );
};

export default FeedingHistory;

import { gql } from '@apollo/client';

// Account related queries

export const ACCOUNT_INFO = gql`
  query MyQuery {
    videoMysteries {
      edges {
        node {
          id
        }
      }
    }
  }
`;

// Mystery related queries

export const GET_MYSTERIES_LIST = gql`
  query MyQuery {
    videoMysteries(orderBy: VID_NUMBER_DESC) {
      edges {
        node {
          id
          link
          createdAt
          nodeId
          thumbnail
          title
          vidNumber
          videoMysterySolutions {
            edges {
              node {
                id
                link
                nodeId
                solutionImg
                solutionText
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MYSTERY_GUESSES = gql`
  query getVideoMysteryGuesses {
    videoMysteryGuesses {
      nodes {
        videoMysteryId
        videoMysterySolutionsId
      }
    }
  }
`;

export const MYSTERY_DETAILS = gql`
  query MyQuery($mysteryId: UUID!) {
    videoMystery(id: $mysteryId) {
      thumbnail
      view
      title
      vidNumber
      link
      id
    }
  }
`;

export const MYSTERY_DETAILS_WITH_SOLUTION = gql`
  query MyQuery($VideoId: UUID!) {
    videoMystery(id: $VideoId) {
      thumbnail
      view
      title
      vidNumber
      link
      id
      nodeId
      videoMysterySolutions {
        nodes {
          id
          link
          nodeId
          solutionImg
          solutionText
        }
      }
    }
  }
`;

export const SHARED_MYSTERIES = gql`
  query getSharedVideos {
    videoMysteryShares {
      nodes {
        id
        videoMysteryId
        createdAt
      }
    }
  }
`;

// Feed related queries

export const CHECK_FEED_DEBIT = gql`
  query checkFeedDebit {
    feedDebits(first: 1, orderBy: CREATED_AT_DESC) {
      edges {
        node {
          id
          accountId
          amount
          createdAt
          nodeId
          reason
        }
      }
    }
  }
`;

// Daily photo related queries

export const ALL_DAILY_PHOTOS = gql`
  query getAllDailyPhotos($offset: Int!) {
    photos(orderBy: CREATED_AT_DESC, first: 10, offset: $offset) {
      nodes {
        id
        title
        thumbnail
        large
        createdAt
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`;

export const SHARED_PHOTOS = gql`
  query SHARED_PHOTOS($accountId: UUID!) {
    photoShares(condition: { accountId: $accountId }) {
      nodes {
        id
        photoId
      }
    }
  }
`;

export const PHOTO_DETAILS = gql`
  query getPhotoDetails($id: UUID!) {
    photo(id: $id) {
      id
      title
      thumbnail
      large
      createdAt
    }
  }
`;

// Raid related queries

export const GET_ALL_RAIDS_USER = gql`
  query getRaid($offset: Int!) {
    raids(orderBy: CREATED_AT_DESC, first: 10, offset: $offset) {
      nodes {
        id
        createdAt
        streamer
      }
      pageInfo {
        hasNextPage
      }
    }
    raidClaims(orderBy: CREATED_AT_DESC, first: 10, offset: $offset) {
      nodes {
        id
        raidId
        accountId
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_RAIDS_PUBLIC = gql`
  query getRaid($offset: Int!) {
    raids(orderBy: CREATED_AT_DESC, first: 10, offset: $offset) {
      nodes {
        id
        createdAt
        streamer
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_AVAILABLE_PROGRESS_REWARDS = gql`
  query allAvailableProgressRewards {
    hatchRewards(orderBy: TOKENS_ASC) {
      nodes {
        id
        img
        nodeId
        tokens
      }
    }
  }
`;

export const PROGRESS_REWARDS_HATCHEDS_BY_USER = gql`
  query progressRewardsHatchedByUser {
    hatchRewardRedeemeds {
      nodes {
        hatchRewardId
        id
        accountId
        createdAt
      }
    }
  }
`;

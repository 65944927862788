import React, { useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [accountId, setAccountId] = useState();
  const [videoMysteries, setvideoMysteries] = useState({});
  const [videoMysteriesAnswer, setvideoMysteriesAnswer] = useState({});
  const [videoMysteriesSolution, setvideoMysteriesSolution] = useState({});
  const [sharedMysteries, setSharedMysteries] = useState([]);
  const [mysteryGuesses, setMysteryGuesses] = useState([]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setAccountId(decodedToken.account_id);
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        accountId,
        videoMysteries,
        setvideoMysteries,
        videoMysteriesSolution,
        setvideoMysteriesSolution,
        videoMysteriesAnswer,
        setvideoMysteriesAnswer,
        sharedMysteries,
        setSharedMysteries,
        mysteryGuesses,
        setMysteryGuesses,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
